import React from "react";
import WidgetWrapper from "./WidgetWrapper";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import useCall from "../contexts/Call";
import useTimer from "../contexts/Timer";
import { useIslandState } from "../contexts/IslandState";
import WidgetAnimatedText from "./WidgetAnimatedText";
import { AnimatePresence } from "framer-motion";
import TimerProgress from "../components/TimerProgress";

const TimerWidget = () => {
  const { homeScreenIconSize, homeScreenGap, innerRadius } = useGlobalMetrics();
  const { islandState, setIslandState } = useIslandState();
  const { callState } = useCall();
  const {
    paused,
    timeInSeconds: timerTimeInSeconds,
    setPause,
    reset,
  } = useTimer();

  const handleClick = () => {
    if (paused) {
      setPause(false);
      if (timerTimeInSeconds === 900) {
        if (islandState !== "call" && !islandState.includes("timer")) {
          setIslandState("timer");
        }
      }
    }
  };

  return (
    <WidgetWrapper
      name="Timer"
      base={{
        className: "relative group bg-[#111111] cursor-pointer select-none",
      }}
    >
      <div
        className="w-full h-full flex flex-col justify-between relative z-10 peer transition-all active:brightness-110"
        style={{
          padding: `${homeScreenGap * 0.5}px`,
        }}
        onClick={handleClick}
      >
        <div
          className="flex items-center justify-start w-full relative z-10"
          style={{
            gap: `${homeScreenGap * 0}px`,
          }}
        >
          <div
            className="flex items-center justify-center"
            style={{
              height: `${homeScreenIconSize * 0.5}px`,
              width: `${homeScreenIconSize * 0.5}px`,
              borderRadius: `${homeScreenIconSize * 0.5}px`,
            }}
          >
            {/* <img
              src="/icons/Stopwatch.svg"
              alt="timer"
              style={{
                width: `${homeScreenIconSize * 0.25}px`,
                height: `${homeScreenIconSize * 0.25}px`,
                filter: `brightness(0) saturate(100%) invert(61%) sepia(55%) saturate(859%) hue-rotate(352deg) brightness(102%) contrast(99%)`,
              }}
            /> */}

            <TimerProgress
              size={homeScreenIconSize * 0.125}
              style={{
                width: `${homeScreenIconSize * 0.2}px`,
                height: `${homeScreenIconSize * 0.2}px`,
              }}
            />
          </div>
          <span
            className="font-bold text-[#fe9f0f]"
            style={{
              fontSize: `${homeScreenIconSize * 0.2}px`,
            }}
          >
            Timer
          </span>
        </div>
        <div
          className="font-semibold text-[#fe9f0f]/40 h-[70%] w-full text-pretty"
          style={{
            fontSize: `${homeScreenIconSize * 0.25}px`,
            padding: `${homeScreenGap * 0.5}px`,
            lineHeight: `${homeScreenIconSize * 0.3}px`,
          }}
        >
          <div className="w-full h-full relative">
            <AnimatePresence mode="popLayout">
              {paused ? (
                timerTimeInSeconds === 900 ? (
                  <WidgetAnimatedText
                    key={"timer-widget-not-started-text"}
                    id={"timer-widget-not-started-text"}
                  >
                    Tap to start a timer of 15 minutes.
                  </WidgetAnimatedText>
                ) : islandState === "call" && callState === "ongoing" ? (
                  <WidgetAnimatedText
                    key={"timer-widget-paused-with-call-ongoing-text"}
                    id={"timer-widget-paused-with-call-ongoing-text"}
                  >
                    Timer is paused. Minimize call to show.
                  </WidgetAnimatedText>
                ) : (
                  <WidgetAnimatedText
                    key={"timer-widget-paused-text"}
                    id={"timer-widget-paused-text"}
                  >
                    Timer is paused.
                  </WidgetAnimatedText>
                )
              ) : islandState === "timer" ? (
                <WidgetAnimatedText
                  key={"timer-widget-running-on-top-text"}
                  id={"timer-widget-running-on-top-text"}
                >
                  Swipe up on island to minimize timer.
                </WidgetAnimatedText>
              ) : islandState === "call" ? (
                callState === "ongoing" ? (
                  <WidgetAnimatedText
                    key={"timer-widget-running-bg-with-ongoing-call-text"}
                    id={"timer-widget-running-bg-with-ongoing-call-text"}
                  >
                    Timer running in background. Minimize call to show.
                  </WidgetAnimatedText>
                ) : (
                  <WidgetAnimatedText
                    key={"timer-widget-running-bg-text"}
                    id={"timer-widget-running-bg-text"}
                  >
                    Timer running in background.
                  </WidgetAnimatedText>
                )
              ) : (
                <WidgetAnimatedText
                  key={"timer-widget-running-text"}
                  id={"timer-widget-running-text"}
                >
                  Timer is running.
                </WidgetAnimatedText>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 top-[50%] bg-gradient-to-b from-[#111] via-[#fe9f0f]/10 to-[#fe9f0f]/25 z-0 transition-all peer-active:top-[0%]"
        style={{
          transitionDuration: "300ms",
          willChange: "transform",
          transitionTimingFunction: "ease-in",
        }}
      ></div>
    </WidgetWrapper>
  );
};

export default TimerWidget;
