import { keyframes, useAnimationControls } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const useSimultaneousAnimation = (
  defaultVariant: {
    [key: string]: any;
  },
  keyframes: {
    [key: string]: any;
  }[]
) => {
  const divControls = useAnimationControls();
  const [defaultVariantState, setDefaultVariantState] = useState<{
    [key: string]: any;
  }>(defaultVariant);

  const isAnimatingRef = useRef(false);

  const animate = async (
    instantKeyframes?: {
      [key: string]: any;
    }[]
  ) => {
    isAnimatingRef.current = true;
    for (const keyframe of instantKeyframes ?? keyframes) {
      if (!isAnimatingRef.current) return;
      await divControls.start(keyframe);
    }
    await divControls.start(defaultVariant);
    isAnimatingRef.current = false;
  };

  useEffect(() => {
    divControls.start(defaultVariant);
    isAnimatingRef.current = false;
  }, [defaultVariant]);

  return {
    animate,
    controls: divControls,
    setDefaultVariant: setDefaultVariantState,
  };
};

export default useSimultaneousAnimation;
