import React, { useState } from "react";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import { AnimatePresence, motion } from "framer-motion";

const WidgetWrapper = ({
  children,
  name,
  layout,
  base,
}: {
  children: React.ReactNode;
  name: string;
  layout?: {
    className?: string;
    style?: React.CSSProperties;
  };
  base?: {
    className?: string;
    style?: React.CSSProperties;
  };
}) => {
  const { homeScreenIconSize, homeScreenGap, innerRadius } = useGlobalMetrics();
  const widgetSize = homeScreenIconSize * 2 + homeScreenGap;

  const layoutStyles = layout?.style ?? {};
  const baseStyles = base?.style ?? {};
  return (
    <motion.div
      className={`flex flex-col items-center justify-center ${
        layout?.className ?? ""
      }`}
      style={{
        width: `${widgetSize}px`,
        gap: `${homeScreenGap * 0.3}px`,
        ...layoutStyles,
      }}
      initial={{
        opacity: 0,
        y: widgetSize / 2,
        scale: 0.9,
        filter: `blur(${homeScreenGap}px)`,
      }}
      animate={{ opacity: 1, y: 0, scale: 1, filter: "blur(0px)" }}
      transition={{
        duration: 0.8,
        delay: 0.6,
      }}
    >
      <div
        className={`overflow-hidden ${base?.className ?? ""}`}
        style={{
          height: `${widgetSize}px`,
          width: `${widgetSize}px`,
          borderRadius: `${innerRadius * 0.4}px`,
          boxShadow: `0 ${homeScreenGap * 0.25}px ${
            homeScreenGap * 0.5
          }px rgb(0 0 0 / 0.2)`,
          ...baseStyles,
        }}
      >
        {children}
      </div>
      <span
        className="text-white font-medium "
        style={{
          fontSize: `${homeScreenGap}px`,
          textShadow: `0 0 ${homeScreenGap * 0.75}px rgb(0 0 0 / 0.8)`,
        }}
      >
        {name}
      </span>
    </motion.div>
  );
};

export default WidgetWrapper;
