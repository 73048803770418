import React from "react";
import StatusBar from "./StatusBar";
import { useGlobalMetrics } from "./contexts/GlobalMetrics";
import { motion } from "framer-motion";
import Widget from "./Widgets";
import TimerWidget from "./Widgets/Timer";
import CallWidget from "./Widgets/Call";

const Phone = () => {
  const {
    screenWidth,
    screenHeight,
    bezel,
    innerRadius,
    outerRadius,
    homeScreenGap,
  } = useGlobalMetrics();
  return (
    <div className="flex items-center justify-center gradient-mask-b-70 overflow-hidden">
      <motion.div
        className="flex items-center justify-center"
        initial={{
          y: screenWidth / 3,
          opacity: 0,
          filter: `blur(${bezel * 1.5}px)`,
        }}
        animate={{
          y: 0,
          opacity: 1,
          filter: "blur(0px)",
        }}
        transition={{
          duration: 1,
          delay: 0.3,
        }}
      >
        <div
          className="relative bg-black flex items-center justify-center"
          style={{
            width: `${screenWidth + bezel * 2}px`,
            paddingTop: `${bezel}px`,
            borderRadius: `${outerRadius}px ${outerRadius}px 0 0`,
            boxShadow: `0 ${-bezel}px ${bezel * 2}px rgb(0 0 0 / 0.3)`,
            margin: `${bezel * 3}px`,
            marginBottom: `0px`,
          }}
        >
          <div
            style={{
              background: "url(/images/background.webp)",
              backgroundSize: "cover",
              backgroundPosition: "top",
              width: `${screenWidth}px`,
              height: `${screenHeight}px`,
              borderRadius: `${innerRadius}px ${innerRadius}px 0 0`,
            }}
            className="relative"
          >
            <StatusBar />
            <div
              className="w-full flex"
              style={{
                padding: `${homeScreenGap * 1}px`,
                gap: `${homeScreenGap * 1}px`,
              }}
            >
              <CallWidget />
              <TimerWidget />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Phone;
