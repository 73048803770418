import { useState, useRef, useCallback, useEffect } from "react";

export const useSwipeThreshold = (
  threshold: number = 100,
  onThresholdReached: () => void
) => {
  const [isDragging, setIsDragging] = useState(false);
  const startYRef = useRef<number | null>(null);
  const elementRef = useRef<HTMLElement | null>(null);

  const handleTouchStart = useCallback((e: TouchEvent) => {
    startYRef.current = e.touches[0].clientY;
    setIsDragging(true);
  }, []);

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (!isDragging || !startYRef.current) return;

      const currentY = e.touches[0].clientY;
      const totalDragDistance = startYRef.current - currentY;

      if (totalDragDistance > threshold) {
        onThresholdReached();
        setIsDragging(false);
      }
    },
    [isDragging, threshold, onThresholdReached]
  );

  const handleTouchEnd = useCallback(() => {
    setIsDragging(false);
    startYRef.current = null;
  }, []);

  const handleMouseDown = useCallback((e: MouseEvent) => {
    startYRef.current = e.clientY;
    setIsDragging(true);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !startYRef.current) return;

      const currentY = e.clientY;
      const totalDragDistance = startYRef.current - currentY;

      if (totalDragDistance > threshold) {
        onThresholdReached();
        setIsDragging(false);
      }
    },
    [isDragging, threshold, onThresholdReached]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    startYRef.current = null;
  }, []);

  useEffect(() => {
    const currentElement = elementRef.current;
    if (!currentElement) return;

    currentElement.addEventListener("touchstart", handleTouchStart);
    currentElement.addEventListener("touchmove", handleTouchMove);
    currentElement.addEventListener("touchend", handleTouchEnd);
    currentElement.addEventListener("mousedown", handleMouseDown);
    currentElement.addEventListener("mousemove", handleMouseMove);
    currentElement.addEventListener("mouseup", handleMouseUp);

    return () => {
      currentElement.removeEventListener("touchstart", handleTouchStart);
      currentElement.removeEventListener("touchmove", handleTouchMove);
      currentElement.removeEventListener("touchend", handleTouchEnd);
      currentElement.removeEventListener("mousedown", handleMouseDown);
      currentElement.removeEventListener("mousemove", handleMouseMove);
      currentElement.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  ]);

  return elementRef as React.MutableRefObject<HTMLDivElement | null>;
};
