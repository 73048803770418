import React from "react";
import useTimer from "../contexts/Timer";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { motion } from "framer-motion";
import { clamp } from "../lib/utils";

const VISUAL_DEVIATION_PERCENTAGE = 5;

const TimerProgress = ({
  size,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  size: number;
}) => {
  const { timeInSeconds } = useTimer();
  return (
    <div {...props} className={`relative ${props.className ?? ""}`}>
      <CircularProgressbarWithChildren
        value={clamp(timeInSeconds / 9 - VISUAL_DEVIATION_PERCENTAGE, 0, 100)}
        strokeWidth={size}
        styles={{
          path: {
            stroke: "#fe9f0f",
            strokeLinecap: "round",
          },
          trail: {
            stroke: "#613c04",
          },
        }}
      >
        <motion.div
          className="absolute bg-[#fe9f0f] h-[30%] rounded-full left-[50%] origin-top"
          style={{
            width: `${size}%`,
            bottom: `${size + size * 0.5}%`,
          }}
          animate={{
            transform: `translateX(-50%) translateY(0%) rotateZ(${
              clamp(
                (timeInSeconds * 2) / 5 - VISUAL_DEVIATION_PERCENTAGE * 3.6,
                0,
                540
              ) - 180
            }deg)`,
          }}
        ></motion.div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default TimerProgress;
