import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useGlobalMetrics } from "../../../contexts/GlobalMetrics";
import useCall from "../../../contexts/Call";
import { useIslandState } from "../../../contexts/IslandState";
import useTimer from "../../../contexts/Timer";
import { useSwipeThreshold } from "../../../hooks/useSwipeUpThreshold";

const IncomingCall = () => {
  const { statusBarFontSize, bezel, dynamicIslandHeight } = useGlobalMetrics();
  const { setIslandState } = useIslandState();
  const {
    callState,
    setCallState,
    formatedCallDuration,
    isSpeakerOn,
    setSpeakerOn,
  } = useCall();
  const { paused: timerPaused, timeInSeconds: timerTimeInSeconds } = useTimer();
  const [resolvedState, setResolvedState] = useState(callState);

  const lastCallStateRef = useRef("idle");

  useEffect(() => {
    if (callState === "ongoing") {
      if (lastCallStateRef.current !== "incoming") {
        lastCallStateRef.current = "ongoing";
        return;
      }
      setResolvedState("idle");
      const timeout = setTimeout(() => {
        setResolvedState("ongoing");
      }, 400);

      lastCallStateRef.current = "idle";
      return () => {
        clearTimeout(timeout);
      };
    } else {
      lastCallStateRef.current = callState;
      setResolvedState(callState);
    }
  }, [callState]);

  const variants = {
    idle: {
      opacity: 0,
      filter: `blur(${statusBarFontSize}px)`,
    },
    ongoing: {
      opacity: 1,
      filter: "blur(0px)",
    },
    incoming: {
      opacity: 1,
      filter: "blur(0px)",
    },
  };

  // This state makes sure to animate to an idle state and then to ongoing state, when user picks the call:
  const timerPausedRef = useRef(timerPaused);
  const timerTimeInSecondsRef = useRef(timerTimeInSeconds);

  useEffect(() => {
    timerPausedRef.current = timerPaused;
  }, [timerPaused]);

  useEffect(() => {
    timerTimeInSecondsRef.current = timerTimeInSeconds;
  }, [timerTimeInSeconds]);

  const minimize = () => {
    if (callState !== "ongoing") return;
    if (timerPausedRef.current && timerTimeInSecondsRef.current === 900) {
      setIslandState("notch:call");
    } else {
      setIslandState("notch:call,notch:timer");
    }
  };

  useEffect(() => {
    if (callState === "ongoing") {
      const timeout = setTimeout(minimize, 3600);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [callState, isSpeakerOn]);

  const swipeUpRef = useSwipeThreshold(dynamicIslandHeight * 0.5, () => {
    minimize();
  });

  return (
    <motion.div
      className="absolute top-0 left-0 w-full h-full flex items-center justify-between text-white"
      ref={swipeUpRef}
      style={{
        padding: `${statusBarFontSize * 0.75}px`,
        gap: `${statusBarFontSize * 0.5}px`,
      }}
      variants={variants}
      initial="idle"
      animate={resolvedState}
      exit="idle"
    >
      <div
        className="flex items-center h-full"
        style={{
          gap: `${statusBarFontSize * 0.5}px`,
        }}
      >
        <div className="h-full aspect-square rounded-full overflow-hidden">
          <img
            src="/images/tim-cook.webp"
            alt="tim cook"
            className="h-full w-full object-cover"
          />
        </div>
        <motion.div
          className="flex flex-col justify-center"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.5 }}
        >
          <AnimatePresence mode="popLayout">
            {callState === "incoming" ? (
              <motion.span
                className="text-gray-400 text-nowrap whitespace-nowrap"
                style={{
                  fontSize: `${statusBarFontSize * 0.7}px`,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={"call-device-name"}
              >
                iPhone
              </motion.span>
            ) : callState === "ongoing" ? (
              <motion.span
                className="text-nowrap whitespace-nowrap text-[#57d76a]"
                style={{
                  fontSize: `${statusBarFontSize * 0.7}px`,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={"call-duration"}
              >
                {formatedCallDuration}
              </motion.span>
            ) : null}
          </AnimatePresence>
          <span
            className="font-medium text-nowrap whitespace-nowrap"
            style={{
              fontSize: `${statusBarFontSize * 0.9}px`,
            }}
          >
            Tim Cook
          </span>
        </motion.div>
      </div>
      <div className="h-full flex items-center" style={{ gap: "0.5rem" }}>
        {callState === "ongoing" && (
          <button
            className={`h-full aspect-square rounded-full flex items-center justify-center transition-colors ${
              isSpeakerOn ? "bg-[#eeeeee]" : "bg-[#373337]"
            }`}
            key={"call-speaker-btn"}
            onClick={() => setSpeakerOn(!isSpeakerOn)}
          >
            <motion.img
              src="/icons/Speaker.svg"
              style={{
                filter: `invert(${isSpeakerOn ? 20 : 100}%)`,
              }}
              initial={{ height: 0 }}
              animate={{
                height: `${statusBarFontSize * 1.6}px`,
              }}
            />
          </button>
        )}
        <button
          className="h-full aspect-square rounded-full flex items-center justify-center bg-[#f35242]"
          key={"call-ender-btn"}
          onClick={() => {
            if (timerPaused && timerTimeInSeconds === 900) {
              setIslandState("idle");
            } else {
              setIslandState("notch:timer");
            }
            setTimeout(() => {
              setCallState("idle");
            }, 20);
          }}
        >
          <motion.img
            src="/icons/Phone.svg"
            initial={{ height: 0 }}
            animate={{
              height: `${statusBarFontSize * 0.6}px`,
            }}
          />
        </button>
        {callState === "incoming" && (
          <button
            className="h-full aspect-square rounded-full flex items-center justify-center bg-[#57d76a]"
            key={"call-picker-btn"}
            onClick={() => setCallState("ongoing")}
          >
            <motion.img
              src="/icons/Phone.svg"
              initial={{ transform: "rotate(225deg)", height: 0 }}
              animate={{
                transform: `rotate(225deg) translateY(-${bezel * 0.25}px)`,
                height: `${statusBarFontSize * 0.6}px`,
              }}
            />
          </button>
        )}
      </div>
    </motion.div>
  );
};

export default IncomingCall;
