import React, { useState } from "react";

export type IslandState =
  | "idle"
  | "call"
  | "timer"
  | "notch:call"
  | "notch:timer"
  | "notch:call,notch:timer";

export type IslandVariant = "idle" | "max" | "min_single" | "min_dual";

type IslandStateContextType = {
  getPreviousIslandState: () => IslandState;
  islandState: IslandState;
  setIslandState: (value: IslandState) => void;
  islandVariant: IslandVariant;
};

const IslandStateContext = React.createContext<
  IslandStateContextType | undefined
>(undefined);

export const IslandStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentIslandState, setCurrentIslandState] =
    useState<IslandState>("idle");
  const previousIslandStateRef = React.useRef<IslandState>("idle");

  const setIslandState = (value: IslandState) => {
    setCurrentIslandState((prev) => {
      if (prev === value) {
        return prev;
      }
      previousIslandStateRef.current = prev;
      return value;
    });
  };

  const getIslandVariant = (): IslandVariant => {
    if (currentIslandState === "idle") {
      return "idle";
    }
    if (currentIslandState.includes("notch")) {
      var count = (currentIslandState.match(/notch/g) || []).length;
      if (currentIslandState.includes(",")) {
        if (count === 1) {
          return "max";
        }
        return "min_dual";
      }
      return "min_single";
    }
    return "max";
  };

  const islandVariant = getIslandVariant();
  const getPreviousIslandState = () => previousIslandStateRef.current;

  return (
    <IslandStateContext.Provider
      value={{
        islandState: currentIslandState,
        getPreviousIslandState,
        setIslandState,
        islandVariant,
      }}
    >
      {children}
    </IslandStateContext.Provider>
  );
};

export const useIslandState = () => {
  const context = React.useContext(IslandStateContext);
  if (context === undefined) {
    throw new Error("useIslandState must be used within a IslandStateProvider");
  }
  return context;
};
