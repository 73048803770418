import React from "react";
import { motion } from "framer-motion";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";

const WidgetAnimatedText = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const { homeScreenGap } = useGlobalMetrics();

  return (
    <motion.span
      key={id}
      className="text-pretty text-right absolute bottom-0 right-0"
      initial={{
        x: homeScreenGap,
        filter: `blur(${homeScreenGap * 0.36}px)`,
        opacity: 0,
      }}
      animate={{
        x: 0,
        filter: `blur(0px)`,
        opacity: 1,
      }}
      exit={{
        x: -homeScreenGap,
        filter: `blur(${homeScreenGap * 0.36}px)`,
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
        ease: "easeOut",
      }}
    >
      {children}
    </motion.span>
  );
};

export default WidgetAnimatedText;
