import React from "react";
import WidgetWrapper from "./WidgetWrapper";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import { useIslandState } from "../contexts/IslandState";
import useCall from "../contexts/Call";
import useTimer from "../contexts/Timer";
import { AnimatePresence, motion } from "framer-motion";
import WidgetAnimatedText from "./WidgetAnimatedText";

const CallWidget = () => {
  const { homeScreenIconSize, homeScreenGap, innerRadius } = useGlobalMetrics();
  const { islandState, setIslandState } = useIslandState();
  const { callState, setCallState } = useCall();
  const {
    paused,
    timeInSeconds: timerTimeInSeconds,
    setPause,
    reset,
  } = useTimer();

  const handleClick = () => {
    console.log(callState);
    if (callState === "idle") {
      setCallState("incoming");
      if (!islandState.includes("call")) {
        setIslandState("call");
      }
    }
  };
  return (
    <WidgetWrapper
      name="Call"
      base={{
        className: "bg-[#111] relative cursor-pointer select-none",
      }}
    >
      <div
        className="w-full h-full flex flex-col justify-between relative z-10 peer transition-all active:brightness-90"
        style={{
          padding: `${homeScreenGap * 0.5}px`,
        }}
        onClick={handleClick}
      >
        <div
          className="flex items-center justify-start w-full relative z-10"
          style={{
            gap: `${homeScreenGap * 0}px`,
          }}
        >
          <div
            className="flex items-center justify-center"
            style={{
              height: `${homeScreenIconSize * 0.5}px`,
              width: `${homeScreenIconSize * 0.5}px`,
              borderRadius: `${homeScreenIconSize * 0.5}px`,
            }}
          >
            <img
              src="/icons/Phone.svg"
              alt="phone"
              style={{
                width: `${homeScreenIconSize * 0.25}px`,
                height: `${homeScreenIconSize * 0.25}px`,
                transform: `rotateZ(-90deg) translateX(${
                  homeScreenIconSize * 0.015
                }px) translateY(${-homeScreenIconSize * 0.005}px)`,
                filter: `brightness(0) saturate(100%) invert(63%) sepia(68%) saturate(2181%) hue-rotate(93deg) brightness(100%) contrast(73%)`,
              }}
            />
          </div>
          <span
            className="font-bold text-green-500"
            style={{
              fontSize: `${homeScreenIconSize * 0.2}px`,
            }}
          >
            Call
          </span>
        </div>
        <div
          className="font-semibold text-green-500/50 h-[70%] w-full text-pretty"
          style={{
            fontSize: `${homeScreenIconSize * 0.25}px`,
            padding: `${homeScreenGap * 0.5}px`,
            lineHeight: `${homeScreenIconSize * 0.3}px`,
          }}
        >
          <div className="w-full h-full relative">
            <AnimatePresence mode="popLayout">
              {callState === "idle" ? (
                <WidgetAnimatedText
                  key={"call-widget-idle-text"}
                  id={"call-widget-idle-text"}
                >
                  Tap to get a call.
                </WidgetAnimatedText>
              ) : callState === "incoming" ? (
                <WidgetAnimatedText
                  key={"call-widget-incoming-text"}
                  id={"call-widget-incoming-text"}
                >
                  It's Tim. Gotta pick it up.
                </WidgetAnimatedText>
              ) : callState === "ongoing" ? (
                islandState === "call" ? (
                  <WidgetAnimatedText
                    key={"call-widget-ongoing-text"}
                    id={"call-widget-ongoing-text"}
                  >
                    Swipe up on island to minimize call.
                  </WidgetAnimatedText>
                ) : islandState === "timer" ? (
                  <WidgetAnimatedText
                    key={"call-widget-ongoing-with-timer-text"}
                    id={"call-widget-ongoing-with-timer-text"}
                  >
                    Call ongoing in background. Minimize timer to show.
                  </WidgetAnimatedText>
                ) : (
                  <WidgetAnimatedText
                    key={"call-widget-ongoing-background-text"}
                    id={"call-widget-ongoing-background-text"}
                  >
                    Call ongoing in background.
                  </WidgetAnimatedText>
                )
              ) : null}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 top-[50%] bg-gradient-to-b from-[#111] via-green-500/10 to-green-500/20 z-0 transition-all peer-active:top-[0%]"
        style={{
          transitionDuration: "300ms",
          willChange: "transform",
          transitionTimingFunction: "ease-in",
        }}
      ></div>
    </WidgetWrapper>
  );
};

export default CallWidget;
