import { motion } from "framer-motion";
import { CircularProgressbar } from "react-circular-progressbar";
import { useGlobalMetrics } from "../../../contexts/GlobalMetrics";
import { useIslandState } from "../../../contexts/IslandState";
import useCall from "../../../contexts/Call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getColorFromNumber } from "../../../lib/utils";
import TimerProgress from "../../../components/TimerProgress";
import useTimer from "../../../contexts/Timer";

const NotchTimer = () => {
  const { statusBarFontSize, dynamicIslandHeight } = useGlobalMetrics();
  const { islandVariant, setIslandState } = useIslandState();
  const { mm, ss, reset } = useTimer();

  useEffect(() => {
    if (mm === "00" && ss === "00") {
      setIslandState("idle");
      reset();
    }
  }, [mm, ss]);
  return (
    <motion.div
      className="w-full h-full flex items-center justify-between text-white scale-100"
      initial={{ opacity: 0, filter: `blur(${statusBarFontSize}px)` }}
      animate={{ opacity: 1, filter: `blur(0px)` }}
      exit={{ opacity: 0, filter: `blur(${statusBarFontSize}px)` }}
      transition={{
        duration: 1,
        type: "spring",
        damping: 13.2,
        stiffness: 130,
      }}
      onClick={() => {
        setIslandState("timer");
      }}
    >
      <TimerProgress
        size={dynamicIslandHeight * 0.32}
        style={{
          height: `${dynamicIslandHeight * 0.5}px`,
          width: `${dynamicIslandHeight * 0.5}px`,
          marginLeft: `${statusBarFontSize * 0.5}px`,
        }}
      />
      <motion.div
        className="flex h-full items-center"
        style={{
          paddingRight: `${statusBarFontSize * 0.45}px`,
        }}
        initial={{
          opacity: 0,
          filter: `blur(${statusBarFontSize * 0.5}px)`,
          x: `${statusBarFontSize * 2}px`,
        }}
        animate={{
          opacity: 1,
          filter: `blur(0px)`,
          x: "0px",
        }}
        transition={{
          type: "spring",
          damping: 13.2,
          stiffness: 130,
          duration: 1,
        }}
        exit={{
          opacity: 0,
          filter: `blur(${statusBarFontSize * 0.5}px)`,
          x: `${statusBarFontSize * 2}px`,
        }}
      >
        <span
          className="font-medium"
          style={{
            color: "#fe9f0f",
            marginLeft: `${statusBarFontSize * 0.1}px`,
            fontSize: `${statusBarFontSize * 0.8}px`,
          }}
        >
          {parseInt(mm) > 9 ? `${mm}m` : `${mm.slice(1)}:${ss}`}
        </span>
      </motion.div>
    </motion.div>
  );
};

export default NotchTimer;
