import React, { useEffect } from "react";

type TimerContextType = {
  timeInSeconds: number;
  mm: `${number}${number}`;
  ss: `${number}${number}`;
  paused: boolean;
  setPause: (value?: boolean) => void;
  reset: () => void;
};

const TimerContext = React.createContext<TimerContextType | undefined>(
  undefined
);

const TIMER_DEFAULT_SECONDS = 900;

export const TimerProvider = ({ children }: { children: React.ReactNode }) => {
  const [timeInSeconds, setTimeInSeconds] = React.useState<number>(
    TIMER_DEFAULT_SECONDS
  );
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  const [paused, setPaused] = React.useState<boolean>(true);

  const mm = `${minutes < 10 ? "0" : ""}${minutes}` as TimerContextType["mm"];
  const ss = `${seconds < 10 ? "0" : ""}${seconds}` as TimerContextType["ss"];

  const setPause = (value?: boolean) => {
    setPaused(value ?? !paused);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      setTimeInSeconds((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [paused, seconds]);

  const reset = () => {
    setPaused(true);
    setTimeInSeconds(TIMER_DEFAULT_SECONDS);
  };

  return (
    <TimerContext.Provider
      value={{ timeInSeconds, mm, ss, paused, setPause, reset }}
    >
      {children}
    </TimerContext.Provider>
  );
};

const useTimer = () => {
  const context = React.useContext(TimerContext);
  if (context === undefined) {
    throw new Error("useTimer must be used within a TimerProvider");
  }
  return context;
};

export default useTimer;
