export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export function getColorFromNumber(number: number, balance?: number): string {
  number = clamp(number, 0, 1);
  balance = clamp(balance ?? 0, 0, 1);

  const redFactor = clamp(number - balance, 0, 1);
  const greenFactor = clamp(number + balance, 0, 1);
  // Calculate the RGB values based on the percentage
  const red = clamp(215 - redFactor * 215, 0, 215); // Red decreases from 255 to 0
  const green = clamp(greenFactor * 215, 0, 215); // Green increases from 0 to 255
  const blue = 0; // Blue remains constant

  // Return the color in RGB format
  return `rgb(${Math.round(red)}, ${Math.round(green)}, ${Math.round(blue)})`;
}
