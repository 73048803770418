import React, { useEffect, useMemo } from "react";
import { useGlobalMetrics } from "../contexts/GlobalMetrics";
import BatteryIndicator from "./BatteryIndicator";
import DynamicIsland from "./DynamicIsland";
import { AnimatePresence, motion } from "framer-motion";
import { IslandVariant, useIslandState } from "../contexts/IslandState";
import useCall from "../contexts/Call";
import useSimultaneousAnimation from "../hooks/useSimultaneousAnimation";

const StatusBar = () => {
  const {
    screenWidth,
    bezel,
    outerRadius,
    statusBarFontSize,
    statusBarXPadding,
    statusBarYPadding,
  } = useGlobalMetrics();
  const { islandVariant, islandState } = useIslandState();
  const { callState } = useCall();

  const timeAnimationVariants: Record<IslandVariant, { [key: string]: any }> =
    useMemo(
      () => ({
        idle: {
          opacity: 1,
          filter: "blur(0px)",
          translateX: "0px",
        },
        max: {
          opacity: 0,
          filter: `blur(${statusBarFontSize * 0.5}px)`,
          translateX: `${-statusBarFontSize * 1}px`,
        },
        min_single: {
          opacity: 1,
          filter: "blur(0px)",

          translateX: `${-statusBarFontSize * 0.5}px`,
        },
        min_dual: {
          opacity: 1,
          filter: "blur(0px)",

          translateX: `${-statusBarFontSize * 0.5}px`,
        },
      }),
      [statusBarFontSize]
    );

  const rightStatusBarAnimationVariants: Record<
    IslandVariant,
    { [key: string]: any }
  > = useMemo(
    () => ({
      idle: {
        opacity: 1,
        filter: "blur(0px)",
        translateX: "0px",
      },
      max: {
        opacity: 0,
        filter: `blur(${statusBarFontSize * 0.5}px)`,
        translateX: `${statusBarFontSize * 1.2}px`,
      },
      min_single: {
        opacity: 1,
        filter: "blur(0px)",
        translateX: `${statusBarFontSize * 0.5}px`,
      },
      min_dual: {
        opacity: 1,
        filter: "blur(0px)",
        translateX: `${statusBarFontSize * 0.5}px`,
      },
    }),
    [statusBarFontSize]
  );

  const {
    animate: timeAnimate,
    controls: timeControls,
    setDefaultVariant: setDefaultTimeAnimationVariant,
  } = useSimultaneousAnimation(timeAnimationVariants[islandVariant], [
    {
      ...timeAnimationVariants["idle"],
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
    {
      ...timeAnimationVariants["max"],
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  ]);

  const {
    animate: rightStatusBarAnimate,
    controls: rightStatusBarControls,
    setDefaultVariant: setDefaultRightStatusBarAnimationVariant,
  } = useSimultaneousAnimation(rightStatusBarAnimationVariants[islandVariant], [
    {
      ...rightStatusBarAnimationVariants["idle"],
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
    {
      ...rightStatusBarAnimationVariants["max"],
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  ]);

  useEffect(() => {
    if (callState === "ongoing") {
      rightStatusBarAnimate();
      timeAnimate();
    }
  }, [callState]);

  useEffect(() => {
    setDefaultTimeAnimationVariant(timeAnimationVariants[islandVariant]);
    setDefaultRightStatusBarAnimationVariant(
      rightStatusBarAnimationVariants[islandVariant]
    );
  }, [islandVariant]);

  return (
    <div className="w-full">
      <div
        className="flex justify-between items-center relative"
        style={{
          padding: `${statusBarYPadding}px ${statusBarXPadding}px`,
        }}
      >
        <motion.div
          className="text-white text-center font-bold"
          style={{
            fontSize: `${statusBarFontSize}px`,
            padding: `0px ${statusBarFontSize * 0.8}px`,
          }}
          initial={timeAnimationVariants["idle"]}
          animate={timeControls}
          transition={{
            type: "spring",
            damping: 13.2,
            stiffness: 130,
          }}
        >
          {new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
          })}
        </motion.div>
        <AnimatePresence>
          <DynamicIsland />
        </AnimatePresence>
        <motion.div
          className="text-white font-bold flex items-center"
          style={{
            fontSize: `${statusBarFontSize}px`,
            gap: `${statusBarFontSize * 0.4}px`,
          }}
          initial={rightStatusBarAnimationVariants["idle"]}
          animate={rightStatusBarControls}
          transition={{
            type: "spring",
            damping: 13.2,
            stiffness: 130,
          }}
        >
          <motion.img
            src="/icons/Network.svg"
            style={{ height: `${statusBarFontSize * 0.9}px` }}
            initial={{
              opacity: 1,
              filter: "blur(0px)",
              x: 0,
            }}
            transition={{
              type: "spring",
              damping: 13.2,
              stiffness: 130,
            }}
            {...(islandVariant.includes("min")
              ? {
                  animate: {
                    opacity: 0,
                    filter: `blur(${statusBarFontSize * 0.2}px)`,
                    x: `${statusBarFontSize}px`,
                  },
                }
              : {})}
          />
          <img
            src="/icons/Wifi.svg"
            style={{ height: `${statusBarFontSize * 0.9}px` }}
          />
          <BatteryIndicator />
        </motion.div>
      </div>
    </div>
  );
};

export default StatusBar;
