import React, { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useGlobalMetrics } from "../../../contexts/GlobalMetrics";
import useTimer from "../../../contexts/Timer";
import { useIslandState } from "../../../contexts/IslandState";
import useCall from "../../../contexts/Call";
import { useSwipeThreshold } from "../../../hooks/useSwipeUpThreshold";

const StopWatch = () => {
  const { statusBarFontSize, bezel, dynamicIslandHeight } = useGlobalMetrics();
  const { islandState, setIslandState } = useIslandState();
  const { mm, ss, paused, setPause, reset } = useTimer();
  const { callState } = useCall();
  const callStateRef = useRef(callState);

  useEffect(() => {
    callStateRef.current = callState;
  }, [callState]);

  const handleStopTimer = () => {
    console.log("stop timer");
    reset();
    if (callState === "ongoing") {
      setIslandState("notch:call");
    } else {
      setIslandState("idle");
    }
  };

  const minimize = () => {
    if (callStateRef.current === "ongoing") {
      setIslandState("notch:call,notch:timer");
    } else {
      setIslandState("notch:timer");
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      minimize();
    }, 3600);
    return () => {
      clearTimeout(timeout);
    };
  }, [paused]);

  const playPauseAnimationVariants: Record<
    "initial" | "animate",
    { [key: string]: any }
  > = {
    initial: {
      height: 0,
      opacity: 0,
      filter: `brightness(0) saturate(100%) invert(75%) sepia(45%) saturate(4214%) hue-rotate(352deg) brightness(101%) contrast(99%) blur(${
        statusBarFontSize * 0.5
      }px)`,
    },
    animate: {
      height: `${statusBarFontSize * 1.4}px`,
      opacity: 1,
      filter: `brightness(0) saturate(100%) invert(75%) sepia(45%) saturate(4214%) hue-rotate(352deg) brightness(101%) contrast(99%) blur(0px)`,
    },
  };

  const swipeUpRef = useSwipeThreshold(dynamicIslandHeight * 0.5, () => {
    minimize();
  });

  return (
    <motion.div
      className="absolute top-0 left-0 w-full h-full flex items-center justify-between text-white rounded-full overflow-hidden"
      ref={swipeUpRef}
      style={{
        padding: `${statusBarFontSize * 0.75}px`,
        gap: `${statusBarFontSize * 0.5}px`,
      }}
      initial={{ opacity: 0, filter: `blur(${statusBarFontSize}px)` }}
      animate={{ opacity: 1, filter: `blur(0px)` }}
      exit={{ opacity: 0, filter: `blur(${statusBarFontSize}px)` }}
    >
      <div className="h-full flex items-center" style={{ gap: "0.5rem" }}>
        <button
          className="h-full aspect-square rounded-full flex items-center justify-center bg-[#4c2e07]"
          onClick={() => setPause(!paused)}
        >
          <AnimatePresence mode="popLayout">
            {paused ? (
              <motion.img
                key={"timer-play-icon"}
                src="/icons/Play.svg"
                variants={playPauseAnimationVariants}
                initial={"initial"}
                animate={"animate"}
                exit={"initial"}
              />
            ) : (
              <motion.img
                key={"timer-pause-icon"}
                src="/icons/Pause.svg"
                variants={playPauseAnimationVariants}
                initial={"initial"}
                animate={"animate"}
                exit={"initial"}
              />
            )}
          </AnimatePresence>
        </button>
        <button
          className="h-full aspect-square rounded-full flex items-center justify-center bg-[#373337]"
          onClick={handleStopTimer}
        >
          <motion.img
            src="/icons/Cross.svg"
            initial={{ height: 0 }}
            animate={{
              height: `${statusBarFontSize * 2}px`,
            }}
            style={{
              filter: "invert(100%)",
            }}
          />
        </button>
      </div>
      <div
        className="flex items-center h-full"
        style={{
          fontSize: `${statusBarFontSize * 0.9}px`,
        }}
      >
        <div className="text-[#fe9f0f] self-end font-medium">Timer</div>
        <div
          className="text-[#fe9f0f] font-thin"
          style={{
            fontSize: `300%`,
            margin: `0 ${statusBarFontSize * 0.25}px`,
          }}
        >
          <AnimatePresence mode="popLayout">
            {mm.split("").map((digit, index) => {
              return (
                <motion.span
                  key={`mm-${digit}-${index}`}
                  className="inline-flex items-center justify-center origin-top"
                  initial={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(${
                      statusBarFontSize * 2
                    }px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  animate={{
                    opacity: 1,
                    transform: `scale(1) translateY(0px)`,
                    filter: `blur(0px)`,
                  }}
                  exit={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(-${statusBarFontSize}px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: (3 - index) * 0.1,
                  }}
                  style={{
                    width: `${statusBarFontSize * 1.5}px`,
                  }}
                >
                  {digit}
                </motion.span>
              );
            })}
          </AnimatePresence>
          :
          <AnimatePresence mode="popLayout">
            {ss.split("").map((digit, index) => {
              return (
                <motion.span
                  key={`ss-${digit}-${index}`}
                  className="inline-flex items-center justify-center origin-top"
                  initial={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(${
                      statusBarFontSize * 2
                    }px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  animate={{
                    opacity: 1,
                    transform: `scale(1) translateY(0px)`,
                    filter: `blur(0px)`,
                  }}
                  exit={{
                    opacity: 0,
                    transform: `scale(0.6) translateY(-${statusBarFontSize}px)`,
                    filter: `blur(${statusBarFontSize * 0.4}px)`,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: (1 - index) * 0.1,
                  }}
                  style={{
                    width: `${statusBarFontSize * 1.5}px`,
                  }}
                >
                  {digit}
                </motion.span>
              );
            })}
          </AnimatePresence>
        </div>
        {/* <div
          className="text-[#fe9f0f] self-start"
          style={{
            fontSize: `80%`,
          }}
        >
          00
        </div> */}
      </div>
    </motion.div>
  );
};

export default StopWatch;
