import { createContext, useContext, useEffect, useRef, useState } from "react";

type CallContextType = {
  callState: "incoming" | "ongoing" | "idle";
  isSpeakerOn: boolean;
  setSpeakerOn: (value: boolean) => void;
  getPreviousCallState: () => CallContextType["callState"];
  setCallState: (value: "incoming" | "ongoing" | "idle") => void;
  formatedCallDuration: string;
};

const CallContext = createContext<CallContextType | undefined>(undefined);

export const CallProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSpeakerOn, setSpeakerOn] = useState<boolean>(false);
  const [currentCallState, setCurrentCallState] =
    useState<CallContextType["callState"]>("idle");
  const previousCallStateRef = useRef<CallContextType["callState"]>("idle");
  const setCallState = (value: CallContextType["callState"]) => {
    setCurrentCallState((prev) => {
      if (prev === value) {
        return prev;
      }
      previousCallStateRef.current = prev;
      return value;
    });
  };
  const [callDuration, setCallDuration] = useState<number>(0);
  const formatedCallDuration = `${Math.floor(callDuration / 60)}:${(
    "00" + Math.floor(callDuration % 60)
  ).slice(-2)}`;

  useEffect(() => {
    if (currentCallState === "ongoing") {
      const interval = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCallDuration(0);
      setSpeakerOn(false);
    }
  }, [currentCallState]);

  const getPreviousCallState = () => previousCallStateRef.current;

  return (
    <CallContext.Provider
      value={{
        callState: currentCallState,
        isSpeakerOn,
        setSpeakerOn,
        getPreviousCallState,
        setCallState,
        formatedCallDuration,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};

const useCall = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error("useCall must be used within a CallProvider");
  }
  return context;
};

export default useCall;
